.header {
  background: '#103856' !important;
  border-bottom: 'none' !important;
}
.circleIcon {
  background-color: #0f1e30;
  cursor: pointer;
}

.headericon-lg {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.header-text {
  color: #fff;
  font-family: 'HM Sans', sans-serif;
  font-size: 25px;
  line-height: normal;
  letter-spacing: -1px;
  font-weight: 100 !important;
}

.headerhmlogo-lg {
  width: 3rem;
  height: 3rem;
  font-size: 3rem;
}

.profileAvatar {
  color: #fff !important;
  background-color: #0f1e30;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #fff;
}

.hmLogo {
  background: url('../images/svgs/hmLogo.svg') no-repeat 0 0;
  width: 140px;
  height: 30px;
}
.logoutIcon {
  background: url('../images/svgs/logoutIcon.svg') no-repeat 0 0;
  width: 32px;
  height: 32px;
}

.header-Split {
  height: 100%;
  border-left: 0.1px solid #fff;
  margin: 0 1rem;
}
