@font-face {
  font-family: 'HM-Slussen-Regular';
  src: url('../fonts/HM-Slussen-Regular.otf');
}

@font-face {
  font-family: 'HM-Slussen-Semibold';
  src: url('../fonts/HM-Slussen-Semibold.otf');
}

@font-face {
  font-family: 'HM-Slussen-Extended-Regular';
  src: url('../fonts/HM-Slussen-Extended-Regular.otf');
}

@font-face {
  font-family: 'HM-Slussen-Extended-Semibold';
  src: url('../fonts/HM-Slussen-Extended-Semibold.otf');
}

@font-face {
  font-family: 'HM-Slussen-Expanded-Bold';
  src: url('../fonts/HM-Slussen-Expanded-Bold.otf');
}

@font-face {
  font-family: 'HM-Slussen-Condensed-Regular';
  src: url('../fonts/HM-Slussen-Condensed-Regular.otf');
}

@font-face {
  font-family: 'HMSansBold';
  src: url('../fonts/HMSansBold.otf');
}

@font-face {
  font-family: 'HM Sans';
  src: url('../fonts/HMSansRegular.otf');
}

@font-face {
  font-family: 'HMSansSemiBold';
  src: url('../fonts/HMSansSemiBold.otf');
}

@font-face {
  font-family: 'HMSansLight';
  src: url('../fonts/HMSansLight.otf');
}

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles

@import '@coreui/chartjs/scss/coreui-chartjs';
@import 'custom';
@import 'header';
.background {
  background-image: url('../images/background.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
}

.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}

.btn-login-button {
  background-color: #000000;
  color: #ffffff;
}

.bg-login {
  background-color: white !important;
  color: #000000;
  opacity: 0.8;
}

.app-logo {
  width: 200px;
  padding: 20px;
}

.themeBackground {
  background: #082337;
}
.g-storehome-Sprite {
  background: url('../images/sprite/home_sprite.svg');
}

.closeIcon {
  background-position: -18px -49px;
  width: 28px;
  height: 28px;
}
.graphcloseIcon {
  background-position: -18px -49px;
  width: 28px;
  height: 28px;
}
.no-data-found-icon {
  background: url('../images/svgs/no-data.svg') no-repeat;
  width: 32px;
  height: 32px;
}
.status_red {
  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #d13232 22.72%, #1e3045 89.76%);
  box-shadow: 3px 4px 15px 1px rgba(0, 0, 0, 0.25);
}
.status_orange,
.status_yellow {
  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #f77c42 22.72%, #1e3045 89.76%);
  box-shadow: 3px 4px 15px 1px rgba(0, 0, 0, 0.25);
}

.status_green {
  border-radius: 8px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #00853d 22.72%, #1e3045 89.76%);
  box-shadow: 3px 4px 15px 1px rgba(0, 0, 0, 0.25);
}
.ant-popover {
  .ant-popover-inner {
    background: none;
    padding: 0;
  }
}
.ant-select-selector,
.ant-select-dropdown {
  border-radius: 3px !important;
}
.ant-select-dropdown {
  max-width: 200px;
}
.customUnderlineTab {
  align-items: end;
  margin-bottom: 10px;

  &.nav-tabs {
    border: none;

    .nav-item {
      .nav-link {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        padding-bottom: 2px;
        color: #222;
        font-family: 'HM Sans', sans-serif;
        cursor: pointer;
        &.active {
          border: 1px solid transparent;
          font-size: 18px;
          border-bottom: 3px solid #000;
          font-weight: 400;
          background: transparent;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.tooltip-inner {
  max-width: 350px !important;
}
.scrollContainer {
  overflow-y: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.scrollContainer::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: transparent;
}

.scrollContainer::-webkit-scrollbar-track {
  margin-top: 5px;
  margin-bottom: 10px;
}

.scrollContainer::-webkit-scrollbar-thumb {
  background: #3a3a3a;
  cursor: pointer;
  border-radius: 8px;
}
.g-Store-error-modal {
  .ant-modal .ant-modal-content {
    height: 200px !important;

    z-index: 1000 !important;
  }
}
.incident-border {
  width: 246px;
  height: 2px;
  margin-left: -22px;
  background: radial-gradient(27.64% 689.29% at 50.05% 0%, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
}
.incident-border-expand {
  height: 2px;
  width: 200px;
  margin-left: -27%;
  background: radial-gradient(27.64% 689.29% at 50.05% 0%, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
}
.incident-border-network {
  height: 2px;
  margin-left: -2px;
  background: radial-gradient(27.64% 689.29% at 50.05% 0%, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
}
.box-flow-title {
  color: #fff;
  font-family: 'HM Slussen Condensed', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 110% */
  
}
.box-flow-title-pos{
  color: #fff;
  font-family: 'HM Slussen Condensed', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 110% */
  position: relative;
  top:40%;
  left:1.5%
}
.no-data-modal {
  color: #000;
  text-align: center;
  font-family: 'HM Slussen', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 122.222% */
}
